<template>
    <div class="address-lookup">
        <div class="form-group">
            <label for="addressSearch">Search Address</label>
            <div class="search-container">
                <input type="text" id="addressSearch" v-model="searchQuery" @input="handleInput"
                    @focus="showResults = true" placeholder="Start typing to search address..." class="search-input" />
                <div v-if="isLoading" class="loading-spinner"></div>
            </div>

            <!-- Search Results -->
            <div v-if="showResults && searchResults.length > 0" class="search-results">
                <div v-for="result in searchResults" :key="result.place_id" @click="selectAddress(result)"
                    class="result-item">
                    {{ result.display_name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddressLookup',
    data() {
        return {
            searchQuery: '',
            searchResults: [],
            showResults: false,
            isLoading: false,
            debounceTimeout: null,
            // State mapping is part of the component's data
            stateMap: {
                'alabama': 'AL',
                'alaska': 'AK',
                'arizona': 'AZ',
                'arkansas': 'AR',
                'california': 'CA',
                'colorado': 'CO',
                'connecticut': 'CT',
                'delaware': 'DE',
                'florida': 'FL',
                'georgia': 'GA',
                'hawaii': 'HI',
                'idaho': 'ID',
                'illinois': 'IL',
                'indiana': 'IN',
                'iowa': 'IA',
                'kansas': 'KS',
                'kentucky': 'KY',
                'louisiana': 'LA',
                'maine': 'ME',
                'maryland': 'MD',
                'massachusetts': 'MA',
                'michigan': 'MI',
                'minnesota': 'MN',
                'mississippi': 'MS',
                'missouri': 'MO',
                'montana': 'MT',
                'nebraska': 'NE',
                'nevada': 'NV',
                'new hampshire': 'NH',
                'new jersey': 'NJ',
                'new mexico': 'NM',
                'new york': 'NY',
                'north carolina': 'NC',
                'north dakota': 'ND',
                'ohio': 'OH',
                'oklahoma': 'OK',
                'oregon': 'OR',
                'pennsylvania': 'PA',
                'rhode island': 'RI',
                'south carolina': 'SC',
                'south dakota': 'SD',
                'tennessee': 'TN',
                'texas': 'TX',
                'utah': 'UT',
                'vermont': 'VT',
                'virginia': 'VA',
                'washington': 'WA',
                'west virginia': 'WV',
                'wisconsin': 'WI',
                'wyoming': 'WY'
            }
        }
    },
    methods: {
        handleInput() {
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }

            this.debounceTimeout = setTimeout(() => {
                this.searchAddress();
            }, 500);
        },

        async searchAddress() {
            if (!this.searchQuery.trim()) {
                this.searchResults = [];
                return;
            }

            this.isLoading = true;
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/search?format=jsonv2&addressdetails=1&q=${encodeURIComponent(
                        this.searchQuery
                    )}&countrycodes=us&limit=5`,
                    {
                        headers: {
                            'Accept-Language': 'en-US'
                        }
                    }
                );
                const data = await response.json();
                this.searchResults = data;
            } catch (error) {
                console.error('Error fetching addresses:', error);
            } finally {
                this.isLoading = false;
            }
        },

        getStateCode(stateName) {
            if (!stateName) return '';
            const stateKey = stateName.toLowerCase().trim();
            return this.stateMap[stateKey] || stateName;
        },

        selectAddress(result) {
            // Get address details from the API response
            const address = result.address;
            console.log('Raw address data:', address); // Debug log

            // Extract street number and street name
            const streetNumber = address.house_number || '';
            const streetName = address.road || address.street || '';
            const address1 = [streetNumber, streetName].filter(Boolean).join(' ');

            // Enhanced city extraction - check all possible OSM city-level fields
            const city = address.city ||
                address.town ||
                address.village ||
                address.municipality ||
                address.suburb ||  // Adding suburb as a fallback
                address.city_district || // Adding city_district as a fallback
                '';

            // Convert state name to 2-digit code
            const stateCode = this.getStateCode(address.state || '');

            // Enhanced postcode handling
            const zipCode = address.postcode ||
                (result.display_name.match(/\b\d{5}\b/) || [''])[0]; // Fallback to extracting from display_name

            // Format county with state code
            const countyBase = (address.county || '')
                .replace(/\s+(County|Parish)$/i, '')
                .trim()
                .toUpperCase();
            const countyFinal = `${countyBase} - ${stateCode}`;

            const addressData = {
                address1: address1,
                address2: '',
                city: city,
                state: stateCode,
                zip: zipCode,
                county: countyFinal
            };

            console.log('Processed address data:', addressData); // Debug log

            // Update search input display
            this.searchQuery = result.display_name;

            // Emit selected address
            this.$emit('address-selected', addressData);

            // Hide results
            this.showResults = false;
            this.searchResults = [];
        }
    }
}
</script>

<style scoped>
.address-lookup {
    width: 100%;
    position: relative;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.search-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 4px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.result-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.result-item:last-child {
    border-bottom: none;
}

.result-item:hover {
    background-color: #f8f9fa;
}

.loading-spinner {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>