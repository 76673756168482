<template>
  <div class="add-customer-form">
    <h2 class="form-title">Add New Customer Address</h2>

    <form @submit.prevent="handleSubmit">
      <div class="form-container">
        <section>
          <h3>Address Information</h3>
          <!-- Address Lookup Component -->
          <AddressLookup @address-selected="handleAddressSelection" />

          <div class="form-group">
            <label for="address1">Address Line 1</label>
            <input v-model="formData.CM04_ADD2" type="text" id="address1" required />
          </div>
          <div class="form-group">
            <label for="address2">Address Line 2</label>
            <input v-model="formData.CM04_ADD3" type="text" id="address2" />
          </div>
          <div class="form-group">
            <label for="city">City</label>
            <input v-model="formData.CM04_CITY" type="text" id="city" required />
          </div>
          <div class="form-group">
            <label for="state">State</label>
            <input v-model="formData.CM04_STAT" type="text" id="state" required />
          </div>
          <div class="form-group">
            <label for="zip">ZIP Code</label>
            <input v-model="formData.CM04_PZIP" type="text" id="zip" required />
          </div>
          <div class="form-group">
            <label for="county">County</label>
            <input v-model="formData.CM04_FLD3" type="text" id="county" required />
          </div>
        </section>
      </div>

      <!-- Submit Button -->
      <button type="submit" class="button submit-button">SUBMIT</button>
    </form>
    <loading-overlay :show="loading" />
    <submission-status :show="showSuccess" @close="handleSuccessClose" />
    <error-status :show="showError" @close="handleErrorClose" @retry="handleRetry" />
  </div>
</template>

<script>
import AddressLookup from './AddressLookup.vue';
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import SubmissionStatus from "@/components/SubmissionStatus.vue";
import ErrorStatus from "@/components/ErrorStatus.vue";

export default {
  name: "AddNewCustomerAddress",
  components: {
    AddressLookup,
    LoadingOverlay,
    SubmissionStatus,
    ErrorStatus
  }
  ,
  data() {
    return {
      loading: false,
      showSuccess: false,
      showError: false,
      formData: {
        FORMTITLE: "New Customer Address",
        CM04_ADD2: "",
        CM04_ADD3: "",
        CM04_CITY: "",
        CM04_STAT: "",
        CM04_PZIP: "",
        CM04_FLD3: ""



      }
    };
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      this.showError = false;

      try {
        console.log('Submitting payload:', this.formData);

        const response = await fetch(process.env.VUE_APP_API_ENDPOINT, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(this.formData)
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Server Error: ${errorText}`);
        }

        this.loading = false;
        this.showSuccess = true;

      } catch (error) {
        console.error("Error submitting form:", error);
        this.loading = false;
        this.showError = true;
      }
    },
    handleAddressSelection(addressData) {
      // Update formData with the selected address details
      this.formData = {
        ...this.formData,  // Preserve other formData properties
        CM04_ADD2: addressData.address1 || '',
        CM04_ADD3: addressData.address2 || '',
        CM04_CITY: addressData.city || '',
        CM04_STAT: addressData.state || '',
        CM04_PZIP: addressData.zip || '',
        CM04_FLD3: addressData.county || ''
      };
    },
    handleSuccessClose() {
      this.showSuccess = false;
      this.$router.push('/');
    },

    handleErrorClose() {
      this.showError = false;
    },

    handleRetry() {
      this.showError = false;
      this.handleSubmit();
    }
  }
};
</script>

<style scoped>
.add-customer-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  width: 100%;
  max-width: 200px;
  padding: 12px 24px;
  font-size: 16px;
  margin: 30px auto;
  display: block;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.submit-button:hover {
  background-color: #0056b3;
}
</style>