<template>
  <div class="search-address">
    <div class="search-input-container">
      <div class="input-wrapper">
        <label for="address">Search Address:</label>
        <div class="input-field-wrapper">
          <input
            v-model="searchQuery"
            type="text"
            id="address"
            :placeholder="loading ? 'Loading...' : 'Enter street address (e.g., 833 ADAMS ST)'"
            class="input-field"
            @keydown.enter.prevent="handleSearch"
            :disabled="loading"
          />
          <button 
            v-if="searchQuery" 
            @click="clearSearch" 
            class="clear-button"
            type="button"
            aria-label="Clear search"
          >
            ×
          </button>
        </div>
      </div>
      <div class="search-button-wrapper">
        <button 
          @click="handleSearch" 
          class="search-button" 
          :disabled="loading || !searchQuery || searchQuery.length < 2"
          type="button"
        >
          Search
        </button>
      </div>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="search-status">
      <span class="loading-spinner"></span>
      Searching addresses...
    </div>

    <!-- Error State -->
    <div v-if="error" class="search-status error">
      {{ error }}
    </div>

    <!-- Results List -->
    <ul v-if="!loading && addresses.length > 0" class="suggestions-list">
      <li
        v-for="address in addresses"
        :key="address.id"
        @click="selectAddress(address)"
        class="suggestion-item"
        :class="{ 'selected': isSelected(address) }"
      >
        <div class="address-main">{{ address.street }}</div>
        <div class="address-secondary">
          {{ address.city }}, {{ address.state }} {{ address.zip }}
        </div>
      </li>
    </ul>

    <!-- No Results State -->
    <div v-else-if="!loading && !error && hasSearched && addresses.length === 0" class="search-status">
      No addresses found. Please try a different search term.<br><br>
      <strong>Quick Tip:</strong> Enter only your house number (e.g., 1245 for "1245 Main St."), then click "Search." Select your address from the list of results that appear.
    </div>

    <!-- Address Validation Modal -->
    <address-validation-modal
      :is-open="showValidationModal"
      :orders="existingOrders"
      @close="handleModalClose"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { fetchAddresses } from "@/utils/getadresses";
import AddressValidationModal from "@/components/AddressModalValidation.vue";

export default {
  name: "SearchAddressLookup",
  components: {
    AddressValidationModal
  },
  props: {
    modelValue: String,
  },
  emits: ['update:modelValue', 'address-selected'],
  
  setup() {
    const route = useRoute();
    return { route };
  },

  data() {
    return {
      searchQuery: "",
      addresses: [],
      loading: false,
      error: null,
      selectedAddress: null,
      hasSearched: false,
      showValidationModal: false,
      existingOrders: [],
      validationLoading: false
    };
  },

  computed: {
    isCartEnrollmentRoute() {
      return this.route.name === 'CartEnrollment';
    }
  },

  methods: {
    async performSearch(query) {
      if (!query || query.length < 2) {
        this.addresses = [];
        this.error = null;
        return;
      }

      this.loading = true;
      this.error = null;
      this.hasSearched = true;

      try {
        const results = await fetchAddresses(query);
        this.addresses = results;
      } catch (error) {
        this.error = "Error searching addresses. Please try again.";
        console.error("Search error:", error);
      } finally {
        this.loading = false;
      }
    },

    async handleSearch() {
      if (this.searchQuery && this.searchQuery.length >= 2) {
        await this.performSearch(this.searchQuery);
      }
    },

    async checkExistingOrders(siteNumber) {
      try {
        const response = await fetch(
          process.env.VUE_APP_CHECKDUPLICATE_URL,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ROUTING_SITE: siteNumber })
          }
        );

        if (!response.ok) {
          throw new Error('Failed to check existing orders');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error checking existing orders:', error);
        throw error;
      }
    },

    async selectAddress(address) {
      this.validationLoading = true;
      try {
        let existingOrders = [];
        
        // Only check for existing orders if we're on the cart-enrollment route
        if (this.isCartEnrollmentRoute) {
          existingOrders = await this.checkExistingOrders(address.siteNumber);
          
          if (existingOrders && existingOrders.length > 0) {
            this.existingOrders = existingOrders;
            this.showValidationModal = true;
            this.validationLoading = false;
            return;
          }
        }

        // Proceed with normal selection
        this.selectedAddress = address;
        this.searchQuery = address.display;
        this.addresses = [];
        this.hasSearched = false;
        
        this.$emit('update:modelValue', address.display);
        this.$emit('address-selected', {
          display: address.display,
          siteNumber: address.siteNumber,
          county: address.county,
          street: address.street,
          city: address.city,
          state: address.state,
          zip: address.zip
        });
      } catch (error) {
        this.error = "Error validating address. Please try again.";
        console.error("Validation error:", error);
      } finally {
        this.validationLoading = false;
      }
    },

    clearSearch() {
      this.searchQuery = "";
      this.addresses = [];
      this.selectedAddress = null;
      this.error = null;
      this.hasSearched = false;
      this.$emit('update:modelValue', '');
    },

    handleModalClose() {
      this.showValidationModal = false;
      this.existingOrders = [];
      this.clearSearch();
    },

    isSelected(address) {
      return this.selectedAddress && this.selectedAddress.id === address.id;
    }
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (newValue !== this.searchQuery) {
          this.searchQuery = newValue || "";
        }
      }
    }
  }
};
</script>

<style scoped>
.search-address {
  position: relative;
  width: 100%;
}

.search-input-container {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.input-wrapper {
  flex: 1;
  min-width: 200px;
}

.input-field-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

label {
  display: block;
  margin-bottom: 4px;
}

.input-field {
  width: 100%;
  padding: 8px 36px 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  height: 40px;
  line-height: 40px;
}

.input-field:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.clear-button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 18px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  line-height: 1;
}

.search-button-wrapper {
  display: flex;
  align-items: flex-end;
}

.search-button {
  height: 40px;
  padding: 0 24px;
  margin: 5px 2px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  white-space: nowrap;
  line-height: 40px;
}

.search-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.search-status {
  padding: 12px;
  text-align: center;
  color: #666;
}

.search-status.error {
  color: #dc3545;
}

.loading-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  margin-right: 8px;
  animation: spin 1s linear infinite;
}

.suggestions-list {
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.suggestion-item:last-child {
  border-bottom: none;
}

.suggestion-item:hover {
  background-color: #f8f9fa;
}

.suggestion-item.selected {
  background-color: #e9ecef;
}

.address-main {
  font-weight: 500;
  margin-bottom: 4px;
}

.address-secondary {
  font-size: 0.9em;
  color: #666;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 640px) {
  .search-input-container {
    flex-direction: column;
  }

  .search-button-wrapper {
    width: 100%;
    justify-content: center;
    margin-top: 4px;
  }

  .search-button {
    width: fit-content;
    padding: 0 48px;
  }

  .input-wrapper {
    width: 100%;
  }
}
</style>