<template>
  <div class="form-group">
    <h2 class="subheading-1">Contact Information</h2>

    <!-- Title -->
    <div class="input-group">
      <label for="title"><strong>Title:</strong></label>
      <select :value="form.title" @change="emitUpdate({ title: $event.target.value })" id="title">
        <option value="Miss.">Miss.</option>
        <option value="Mrs.">Mrs.</option>
        <option value="Mr.">Mr.</option>
        <option value="Dr.">Dr.</option>
        <option value="Prof.">Prof.</option>
      </select>
    </div>

    <!-- First Name -->
    <div class="input-group">
      <label for="firstName"><strong>First Name:</strong></label>
      <input :value="form.firstName" @input="emitUpdate({ firstName: $event.target.value })" type="text" id="firstName"
        placeholder="Enter your first name" required />
    </div>

    <!-- Last Name -->
    <div class="input-group">
      <label for="lastName"><strong>Last Name:</strong></label>
      <input :value="form.lastName" @input="emitUpdate({ lastName: $event.target.value })" type="text" id="lastName"
        placeholder="Enter your last name" required />
    </div>

    <!-- Email field -->
    <div class="input-group">
      <label for="email"><strong>Email:</strong></label>
      <EmailInput :model-value="form.email" @update:model-value="handleEmailUpdate" @validation="handleEmailValidation"
        placeholder="Enter your email address" />
    </div>

    <!-- Phone Section -->
    <div class="input-group">
      <label><strong>Phone Number:</strong></label>
      <div class="phone-input-container">
        <select :value="form.phoneType" @change="emitUpdate({ phoneType: $event.target.value })"
          class="phone-type-select">
          <option value="mobile">Mobile</option>
          <option value="landline">Landline</option>
        </select>
        <div class="phone-input-wrapper">
          <input :value="displayPhoneNumber" @input="handlePhoneInput" @blur="phoneFieldTouched = true" type="tel"
            placeholder="123-456-7890" class="phone-input" :class="{ 'invalid': showPhoneError }" required
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
          <div v-if="showPhoneError" class="error-message">
            Please enter exactly 10 digits
          </div>
          <div v-else-if="isPhoneValid" class="success-message">
            Valid phone number
          </div>
        </div>
      </div>
    </div>

    <!-- Service Updates Toggle -->
    <ToggleSwitch label="Cart Delivery & Service Notifications"
      hint="Receive notifications about cart deliveries, exchanges and service updates."
      :model-value="form.serviceupdate" @update:model-value="(value) => emitUpdate({ serviceupdate: value })" />

    <!-- Marketing Toggle -->
    <ToggleSwitch label="Marketing Communications" hint="Receive other service offers and deals from LRS marketing."
      :model-value="form.marketing" @update:model-value="(value) => emitUpdate({ marketing: value })" />

    <!-- Contact Methods -->
    <ContactMethodSelect label="Preferred Method of Contact" :model-value="form.contactMethod"
      :options="['Phone', 'Text', 'Email']" @update:model-value="(value) => emitUpdate({ contactMethod: value })" />
  </div>
</template>

<script>
import ToggleSwitch from '../ToggleSwitch.vue'
import ContactMethodSelect from '../ContactMethodSelect.vue'
import EmailInput from '../EmailInput.vue'

export default {
  name: 'Step1ContactInformation',
  components: {
    ToggleSwitch,
    ContactMethodSelect,
    EmailInput
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      phoneFieldTouched: false,
      isEmailValid: false
    }
  },
  computed: {
    isPhoneValid() {
      const digits = this.form.phoneNumber?.replace(/\D/g, '') || '';
      return digits.length === 10; // Must be exactly 10 digits
    },
    showPhoneError() {
      return !this.isPhoneValid && this.phoneFieldTouched && this.form.phoneNumber;
    },
    displayPhoneNumber() {
      const digits = this.form.phoneNumber || '';
      return this.formatPhoneNumber(digits.slice(0, 10)); // Ensure we only display up to 10 digits
    }
  },
  methods: {
    emitUpdate(updates) {
      const emailWasUpdated = Object.prototype.hasOwnProperty.call(updates, 'email');
      this.$emit('update-form', {
        ...updates,
        isEmailValid: emailWasUpdated ? false : this.isEmailValid,
        isPhoneValid: updates.phoneNumber ? this.isPhoneValid : this.form.isPhoneValid // Add phone validation state
      });
    },

    handleEmailValidation(isValid) {
      console.log('Email validation state:', isValid);
      this.isEmailValid = isValid;
      this.$emit('update-form', { isEmailValid: isValid });
    },

    handleEmailUpdate(value) {
      this.emitUpdate({
        email: value,
        isEmailValid: false // Reset validation on new input
      });
    },
    handlePhoneInput(event) {
      // Get only digits and limit to 10
      const digits = event.target.value.replace(/\D/g, '').slice(0, 10);

      this.emitUpdate({
        phoneNumber: digits,
        isPhoneValid: digits.length === 10
      });
    },
    formatPhoneNumber(digits) {
      if (!digits) return '';
      if (digits.length <= 3) return digits;
      if (digits.length <= 6) return `${digits.slice(0, 3)}-${digits.slice(3)}`;
      return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    }
  }
};
</script>

<style scoped>
.phone-input-container {
  display: flex;
  gap: 8px;
}

.phone-type-select {
  width: 120px;
}

.phone-input-wrapper {
  flex: 1;
}

.phone-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.2s;
}

.phone-input.invalid {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.success-message {
  color: #198754;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
}

input,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

input:focus,
select:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
}
</style>