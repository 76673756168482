<template>
  <div class="elgin-resident-portal">
    <h1 class="h1">Contact Us</h1>
    <!-- Section 1 -->
    <section class="responsive-section">
      <br>
        <!-- Column 1 Content -->
<body>

  <div class="container">
    <!-- Left Column -->
     
    <div class="contact-info">

      <h2 class="h2">City of Elgin</h2>
      <button 
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('tel:847-931-6001');">
            By Phone at<br>ELGIN 311 / 847.931.6001
        </button><br><br>            

        <button 
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('mailto:elgin311@cityofelgin.org', '_blank');">
            By Email at<br>elgin311@cityofelgin.org
        </button><br><br>
        <h2 class="h2">LRS</h2>
        <button 
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('tel:855-612-7938');">
            By Phone at<br>855.612.7938
        </button><br><br>            

        <button 
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('mailto:elginservice@lrsrecycles.com', '_blank');">
            By Email at<br>ElginService@LRSrecycles.com
        </button><br><br>

        <button 
            class="lrs-button2" 
            style="width: 275px;" 
            onclick="window.open('https://payment.lrsrecycles.com/portal/templanding/', '_blank');">
            LRS Online Bill Pay
        </button><br><br>

            <h2 class="h2">Download Brochure</h2>
            <a href="/ElginBroEng.pdf" target="_blank" rel="noopener noreferrer"><img src="@/assets/broEng.png" alt="Elgin Brochure" class="image"></a>
        &nbsp;&nbsp;<a href="/ElginBroEsp.pdf" target="_blank" rel="noopener noreferrer"><img src="@/assets/broEsp.png" alt="Elgin Brochure" class="image"></a>
      </div>

    <!-- Right Column -->
    <div class="contact-image">
      <img src="@\assets\CurattoFLTruck_Resi_3.jpg" alt="LRS Truch" class="responsive-image">
    </div>
  </div>
</body>

    </section>

    <!-- Section 2 -->
    <section class="section-2">
      <hr class="horizontal-line">
    </section>

    <!-- Section 3 -->
    <section class="section-3">
      <div class="column column-3">


          

 
      </div>

      <div class="column column-4">


   

  
      </div>

      <div class="column column-5">

      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
};
</script>

<style scoped>
.elgin-resident-portal {
  padding: 20px;
}

.section-1 {
  display: flex;
  gap: 20px;
}

.column {
  box-sizing: border-box;
}

.column-1 {
  width: 50%;
}

.column-2 {
  width: 50%;
}

.section-2 {
  margin: 20px 0;
}

.horizontal-line {
  height: 5px;
  background-color: var(--lrs-blue);
  border: none;
}

.section-3 {
  display: flex;
  gap: 20px;
}

.column-3,
.column-4,
.column-5 {
  width: 33%;
  text-align: left;
}

.separator {
  border: none;
  border-top: 1px solid var(--white);
  margin: 10px 0;
}

.elgin-image {
  width: 100%;
  margin-bottom: 20px;
}
/* Responsive Column Adjustments */
@media (max-width: 768px) {

.column-1,
.column-2,
.column-3,
.column-4,
.column-5 {
  flex: 1 1 100%;
  text-align: left;
  /* Adjust text alignment for mobile */
}
.h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 32px;
  color: var(--lrs-blue);
}

.h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: var(--recycle-green);
  margin-top: 10px;
  margin-bottom: 10px;
}
.subheading-1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: var(--safety-orange);
}
.h3a {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--lrs-blue);
}
}
/* Media Queries for Smaller Devices */
@media (max-width: 768px) {

.section-1,
.section-3 {
  flex-direction: column;
  align-items: center;
}

.column {
  width: 100%;
}

.card {
  width: 90%;
  margin: 10px 0;
}

.elgin-resident-portal {
  padding: 10px;
}
}
</style>
