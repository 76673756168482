<template>
    <div class="form-group">
      <h2 class="subheading-1">Address Information</h2>

      <p class="paragraph">
        To look up address:
        <ul>
          <li>Enter Street Number (i.e., "200" for "200 Main St.")</li>
          <li>Click Search</li>
          <li>Scroll through the resulting addresses to pick your address.</li>
        </ul>
      </p>

      <search-address-lookup 
        :model-value="form.address"
        @update:model-value="updateAddress"
        @address-selected="handleAddressSelection" 
      />
      <!-- Add validation message -->
      <p v-if="!isAddressValid" class="validation-message">Please select an address to continue</p>
      <!-- Display selected address details -->
      <div v-if="isAddressValid" class="selected-address-details">
        <p><strong>Selected Address:</strong></p>
        <p>Street: {{ form.address1 }}</p>
        <p>City: {{ form.city }}</p>
        <p>State: {{ form.state }}</p>
        <p>ZIP: {{ form.zip }}</p>
        <p>County: {{ form.county }}</p>
      </div>
      <p class="paragraph">
        If your address is not found please contact the City of Elgin at
        <a href="tel:847-931-6001">847.931.6001</a> or
        <a href="mailto:Elgin311@cityofelgin.org">Elgin311@cityofelgin.org</a>
        for assistance.
      </p>
    </div>
  </template>
  
  <script>
  import SearchAddressLookup from "@/components/SearchAddressLookup.vue";
  
  export default {
    components: {
      SearchAddressLookup
    },
    props: {
      form: {
        type: Object,
        required: true
      }
    },
    emits: ['update-form'],
    computed: {
      isAddressValid() {
        return Boolean(
          this.form.address1 &&
          this.form.city &&
          this.form.state &&
          this.form.zip
        );
      }
    },
    methods: {
      updateAddress(value) {
        this.$emit('update-form', { address: value });
      },
      handleAddressSelection(addressData) {
        const { display, siteNumber, county } = addressData;
        const parts = display.split(',').map(part => part.trim());
        
        if (parts.length >= 4) {
          const updates = {
            address: display,
            address1: parts[0],
            city: parts[1],
            state: parts[2],
            zip: parts[3].trim(),
            siteNumber: siteNumber,
            county: county
          };
          
          this.$emit('update-form', updates);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .validation-message {
    color: red;
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }
  
  .selected-address-details {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 4px;
  }
  </style>