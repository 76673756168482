<template>
    <div class="elgin-resident-portal">



<!-- Section 1 -->
<section class="responsive-section">
  <br>
  <br>
  <h2 class="h2">Household Hazardous Waste Pickup Request</h2><br>

  <div class="jotform-container">
    <!-- Embed JotForm using iframe -->
    <iframe
  src="https://lrsrecycles.jotform.com/233125184313144?redirect=https://nice-water-064044e10.5.azurestaticapps.net/"
  frameborder="0"
  style="width: 100%; height: 1000px;"
  allowfullscreen
></iframe>
  </div>
  </section>
  </div>
  
</template>

<script>
export default {
  name: "JotFormPage",
};
</script>

<style scoped>
.jotform-container {
  width: 100%;
  max-width: 800px; /* Adjust based on your layout */
  margin: 0 auto; /* Center the form */
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>

