<template>
  <div class="elgin-resident-portal">



<!-- Section 1 -->
<section class="responsive-section">
  <div class="column column-1">
    <!-- Column 1 Content -->
<br>
<h1 class="h1">Damaged and Broken Carts</h1><br>


<div class="container">
  <!-- Left Column -->
  <div class="contact-info">
  <p class="paragraph">
        If you have a damaged or broken cart, please contact the City of Elgin. 
      </p> 

      <button class="lrs-button" style="width: 275px;">By Phone at <a
          href="tel:847-931-6001">ELGIN 311 / 847.931.6001</a></button><br><br>
      <button class="lrs-button" style="width: 275px;">By Email at <a
        href="mailto:elgin311@cityofelgin.org">elgin311@cityofelgin.org</a></button><br><br>

        <h2 class="h2">CART MAINTENANCE</h2>
      
      <h2 class="subheading-2">Carts that are dirty or have an odor will not be replaced.</h2>

      <p class="paragraph">   <br>
           
        <strong>How to clean your cart:</strong>

        <ul>
<li>Fill the cart with hot water and a capful of vinegar or grease-cutting dish soap.</li>
<li>Let it sit for about 15 minutes and rinse with warm water.</li>
<li>Once the cart has been thoroughly rinsed, set the cart out with the lid open in the sun to dry or keep in a warm place until dry.</li>

</ul>

</p> 
  </div>

  <!-- Right Column -->
  <div class="contact-image">
    <img src="@\assets\Cart.png" alt="Elgin Waste Can">
  </div>
</div>

  </div>
  </section>


  </div>
</template>

<style scoped>
/* Responsive Column Adjustments */
@media (max-width: 768px) {

.column-1,
.column-2,
.column-3,
.column-4,
.column-5 {
  flex: 1 1 100%;
  text-align: left;
  /* Adjust text alignment for mobile */
}
.h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 28px;
  color: var(--lrs-blue);
}

.h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: var(--recycle-green);
  margin-top: 10px;
  margin-bottom: 10px;
}
.subheading-1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: var(--safety-orange);
}
.h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--recycle-green);
}
}

/* Media Queries for Smaller Devices */
@media (max-width: 768px) {

  .section-1,
  .section-3 {
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%;
  }

  .card {
    width: 90%;
    margin: 10px 0;
  }

  .elgin-resident-portal {
    padding: 10px;
  }
}

</style>
