// src/utils/emailValidator.js
export const isValidEmailFormat = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validateEmailMX = async (email) => {
  // First do basic format check
  if (!isValidEmailFormat(email)) {
    return {
      isValid: false,
      message: "Invalid email format",
      checking: false
    };
  }

  try {
    const response = await fetch(`https://api.eva.pingutil.com/email?email=${email}`);
    
    if (!response.ok) {
      return {
        isValid: true,
        message: "",
        checking: false
      };
    }

    const data = await response.json();
    const isValid = data.status === "success" && data.data.valid_syntax;

    return {
      isValid,
      message: isValid ? "" : "Invalid email address",
      checking: false
    };

  } catch (error) {
    return {
      isValid: true,
      message: "",
      checking: false
    };
  }
};