<template>
  <div class="cart-options">
    <div class="info-section">
      <h2 class="subheading-1">Confirm Your Current Cart Sizes</h2>
      <p class="paragraph">
        Please confirm your current cart sizes for waste and recycling service.
        This helps us ensure accurate service records.
      </p>
    </div>

    <!-- Waste Cart Option -->
    <div class="option-card">
      <div class="option-header">
        <i class="fas fa-trash-alt icon"></i>
        <h3>Waste Cart</h3>
      </div>

      <div class="cart-options">
           <div class="cart-color-toggle">
            <toggle-switch label="I have a 35-gallon waste cart." :model-value="form.wasteCart35"
              @update:model-value="handleWasteCart35"
              hint="Please confirm that you have a 35-gallon waste cart that you intend to keep." />
          </div>

        <p class="paragraph">
              By selecting this option, I understand that:
            </p>
                <ul>
                  <li>I will be billed the rate for a 35-gallon waste cart by the City of Elgin for this service.</li>
                  <li>Waste cart service rates are updated quarterly by the City of Elgin (January, April, July, and October).</li>
                </ul>




    

      </div>
    </div>

    <!-- Recycling Cart Option -->
    <div class="option-card">
      <div class="option-header">
        <i class="fas fa-recycle icon"></i>
        <h3>Recycling Cart</h3>
      </div>
      <div class="cart-options">
           <div class="cart-color-toggle">
            <toggle-switch label="I have a 35-gallon recycle cart." :model-value="form.recycleCart35"
              @update:model-value="handleRecycleCart35"
              hint="Please confirm that you have a 35-gallon recycling cart that you intend to keep." />
          </div>


        </div>
      
    </div>
  </div>

</template>

<script>
import ToggleSwitch from '@/components/ToggleSwitch.vue';

export default {
  name: 'ConfirmCartSize',
  components: {
    ToggleSwitch
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleWasteSize(event) {
      console.log('Waste cart selection:', event.target.value);
      this.$emit('update-form', {
        ...this.form,
        currentWasteCart: event.target.value
      });
    },
    handleRecycleSize(event) {
      console.log('Recycle cart selection:', event.target.value);
      this.$emit('update-form', {
        ...this.form,
        currentRecycleCart: event.target.value
      });
    },
   
    handleWasteCartColor(value) {
      console.log('Recycle cart is green:', value);
      this.$emit('update-form', {
        ...this.form,
        wasteCartIsGreen: value
      });
    },
    handleRecycleCart35(value) {
      console.log('Recycle cart is 35:', value);
      this.$emit('update-form', {
        ...this.form,
        recycleCart35: value
      });
    },
    handleWasteCart35(value) {
      console.log('Waste cart is 35:', value);
      this.$emit('update-form', {
        ...this.form,
        wasteCart35: value
      });
    }
  }
};
</script>

<style scoped>
.cart-options {
  max-width: 800px;
  margin: 0 auto;
}

.info-section {
  margin-bottom: 2rem;
  text-align: center;
}

.option-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.option-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.icon {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-right: 1rem;
}

.option-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: var(--text-primary);
}

.option-content {
  padding-left: 2.5rem;
}

.size-selection {
  margin-bottom: 1rem;
}

.size-select {
  width: 100%;
  max-width: 200px;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
}

.size-notice {
  margin-top: 0.75rem;
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  border-left: 4px solid #0066cc;
}

.price-note {
  margin: 0;
  font-size: 0.9rem;
  color: #0066cc;
  font-weight: 500;
}

.cart-color-toggle {
  margin-top: 1rem;
}
</style>