<template>
  <div class="modal-overlay" v-if="show">
    <div class="modal-container">
      <div class="modal-header">
        <h2>Cart Size Confirmation</h2>
        <button class="close-button" @click="handleClose">&times;</button>
      </div>

      <div class="modal-body">
        <!-- Step Indicator -->
        <div class="step-indicator">
          <div v-for="stepNum in 3" :key="stepNum" :class="['step', { active: currentStep === stepNum }]">
            Step {{ stepNum }}
          </div>
        </div>

        <!-- Step Components -->
        <keep-alive>
          <component :is="currentStepComponent" :form="formData" @update-form="updateFormData" />
        </keep-alive>
      </div>

      <div class="modal-footer">
        <button v-if="currentStep > 1" @click="prevStep" class="button secondary">
          Previous
        </button>&nbsp;&nbsp;
        <button v-if="currentStep < 3" @click="nextStep" :disabled="!isCurrentStepValid" class="button primary">
          Next
        </button>&nbsp;&nbsp;
        <button v-if="currentStep === 3" @click="handleSubmit" :disabled="!isCurrentStepValid || loading"
          class="button success">
          {{ loading ? 'Submitting...' : 'Submit' }}
        </button>
      </div>

      <!-- Status Components -->
      <loading-overlay :show="loading" />
      <submission-status :show="showSuccess" @close="handleSuccessClose" />
      <error-status :show="showError" @close="handleErrorClose" @retry="handleRetry" />
    </div>
  </div>
</template>

<script>
import Step1ContactInformation from '@/components/Steps/Step1ContactInformation.vue';
import Step2AddressInformation from '@/components/Steps/Step2AddressInformation.vue';
import ConfirmCartSize from '@/components/Steps/StepConfirmCurrentSize.vue';
import { NotificationService } from '@/utils/notificationService';
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import SubmissionStatus from "@/components/SubmissionStatus.vue";
import ErrorStatus from "@/components/ErrorStatus.vue";

const generateGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export default {
  name: 'CartSizeModal',
  components: {
    Step1ContactInformation,
    Step2AddressInformation,
    ConfirmCartSize,
    LoadingOverlay,
    SubmissionStatus,
    ErrorStatus
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      currentStep: 1,
      isSubmitting: false,
      loading: false,
      showSuccess: false,
      showError: false,
      isEmailValid: false,
      formData: {
        // Address Information
        address: "",
        address1: "",
        address2: "",
        city: "ELGIN",
        state: "IL",
        zip: "",
        county: "",
        siteNumber: null,

        // Cart Selection Fields
        wasteCart35: false,
        wasteCart64: false,
        wasteCart96: false,
        recycleCart35: false,
        recycleCart64: false,
        recycleCart96: false,

        // Contact Information
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneType: "mobile",
        phoneNumber: "",
        serviceupdate: true,
        marketing: true,
        contactMethod: [],

        // Additional fields for validation
        UpsizeAck: false,
        RenewalAck: false,
      }
    };
  },
  // Replace the nested computed section with this:
  computed: {
    currentStepComponent() {
      const steps = {
        1: 'Step2AddressInformation',
        2: 'ConfirmCartSize',
        3: 'Step1ContactInformation'
      };
      return steps[this.currentStep];
    },
    isCurrentStepValid() {
      this.logFormState();

      // Move declarations outside switch
      const phoneDigits = this.formData.phoneNumber?.replace(/\D/g, '') || '';
      const isPhoneValid = phoneDigits.length === 10;

      switch (this.currentStep) {
        case 1:
          return Boolean(
            this.formData.address1 &&
            this.formData.city &&
            this.formData.state &&
            this.formData.zip &&
            this.formData.siteNumber
          );
        case 2:
          return Boolean(
            this.formData.wasteCart35 ||
            this.formData.wasteCart64 ||
            this.formData.wasteCart96 ||
            this.formData.recycleCart35 ||
            this.formData.recycleCart64 ||
            this.formData.recycleCart96
          );
        case 3:
          return Boolean(
            this.formData.firstName &&
            this.formData.lastName &&
            this.formData.email &&
            this.formData.isEmailValid &&
            isPhoneValid
          );
        default:
          return false;
      }
    },
    needs96GallonAcknowledgement() {
      return this.formData.wasteCart96 || this.formData.recycleCart96;
    }
  },
  methods: {
    logFormState() {
      console.log('Current Form State:', {
        step: this.currentStep,
        cartSelections: {
          waste: {
            '35': this.formData.wasteCart35,
            '64': this.formData.wasteCart64,
            '96': this.formData.wasteCart96
          },
          recycle: {
            '35': this.formData.recycleCart35,
            '64': this.formData.recycleCart64,
            '96': this.formData.recycleCart96
          }
        },
        address: {
          address1: this.formData.address1,
          city: this.formData.city,
          state: this.formData.state,
          zip: this.formData.zip,
          siteNumber: this.formData.siteNumber
        },
        contact: {
          name: `${this.formData.firstName} ${this.formData.lastName}`,
          email: this.formData.email,
          phone: this.formData.phoneNumber
        }
      });
    },

    updateFormData(updates) {
      console.log('Form update received:', updates);
      this.formData = { ...this.formData, ...updates };
      this.logFormState();
    },

    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    nextStep() {
      if (this.currentStep < 3 && this.isCurrentStepValid) {
        this.currentStep++;
      }
    },

    generateCartSizeConfirmation() {
      console.log('Generating cart confirmation with form data:', this.formData);

      const baseInfo = {
        SITEGUID: generateGUID(),
        FORMTITLE: "Cart Size Confirmation Only", // Updated to match expected value
        SourceFormName: "Cart Size Confirmation Only", // Added explicit SourceFormName
        CM05_CUST: 26898,
        CM05_SITE: this.formData.siteNumber,
        CM05_TYPE: null,
        CM05_SIZE: null,
        CURRENTSIZE: null,

        // Contact Information
        CC01_SALU: this.formData.title || '',
        CC01_FNAME: this.formData.firstName.toUpperCase(),
        CC01_LNAME: this.formData.lastName.toUpperCase(),
        CC01_PHONETYPE: this.formData.phoneType,
        CC01_PHONE: this.formData.phoneNumber,
        CC01_EMAIL: this.formData.email,

        // Address Information
        CM04_NAME: `${this.formData.firstName} ${this.formData.lastName}`.toUpperCase(),
        CM04_FLD3: this.formData.county,
        CM04_ADD2: this.formData.address1,
        CM04_ADD3: this.formData.address2 || "",
        CM04_CITY: this.formData.city,
        CM04_STAT: this.formData.state,
        CM04_PZIP: this.formData.zip,
        CM04_ISMAILSAME: true,

        // Flags and Preferences
        SERVICE_UPDATES_FLAG: this.formData.serviceupdate ? 1 : 0,
        MARKETING_UPDATES_FLAG: this.formData.marketing ? 1 : 0,
        PREFERRED_CONTACT_METHOD: this.formData.contactMethod.join(","),
        FORM_COMPLETED_BY: "Resident"
      };

      const confirmationPayloads = [];

      // Handle waste carts (35/64)
      if (this.formData.wasteCart35 || this.formData.wasteCart64) {
        const size = this.formData.wasteCart35 ? 35 : 64;
        confirmationPayloads.push({
          ...baseInfo,
          CM05_TYPE: "RG",
          CM05_SIZE: size,
          CURRENTSIZE: size
        });
      }

      // Handle recycle carts (35/64)
      if (this.formData.recycleCart35 || this.formData.recycleCart64) {
        const size = this.formData.recycleCart35 ? 35 : 64;
        confirmationPayloads.push({
          ...baseInfo,
          CM05_TYPE: "RR",
          CM05_SIZE: size,
          CURRENTSIZE: size
        });
      }

      console.log('Generated payloads:', confirmationPayloads);
      return confirmationPayloads;
    },

    generateServicePayload() {
      const baseInfo = {
        SITEGUID: generateGUID(),
        FORMTITLE: "Create Special Service",
        // Required fields
        CM05_CUST: 26898,
        CM05_SITE: this.formData.siteNumber,
        CM05_TYPE: null, // Will be set for each service
        CM05_SIZE: 96.0, // Fixed for 96-gallon carts
        CURRENTSIZE: null, // Will be set for each service

        // Contact Information
        CC01_SALU: this.formData.title || '',
        CC01_FNAME: this.formData.firstName.toUpperCase(),
        CC01_LNAME: this.formData.lastName.toUpperCase(),
        CC01_PHONETYPE: this.formData.phoneType,
        CC01_PHONE: this.formData.phoneNumber,
        CC01_EMAIL: this.formData.email,

        // Address Information
        CM04_NAME: `${this.formData.firstName} ${this.formData.lastName}`.toUpperCase(),
        CM04_FLD3: this.formData.county,
        CM04_ADD2: this.formData.address1,
        CM04_ADD3: this.formData.address2 || "",
        CM04_CITY: this.formData.city,
        CM04_STAT: this.formData.state,
        CM04_PZIP: this.formData.zip,
        CM04_ISMAILSAME: true,

        // Flags and Preferences
        SERVICE_UPDATES_FLAG: this.formData.serviceupdate ? 1 : 0,
        MARKETING_UPDATES_FLAG: this.formData.marketing ? 1 : 0,
        PREFERRED_CONTACT_METHOD: this.formData.contactMethod.join(","),
        FORM_COMPLETED_BY: "Resident",

        services: []
      };

      if (this.formData.wasteCart96) {
        baseInfo.services.push({
          SERVGUID: generateGUID(),
          CM05_CUST: 26898,
          CM05_SITE: this.formData.siteNumber,
          CM05_TYPE: "RG",
          CM05_SIZE: 96.0,
          CURRENTSIZE: this.formData.currentWasteCart || 96.0,
          CM05_QTY: 1,
          CM19_ACTV: "PS",
          CM19_RATE: (33.80 / 12).toFixed(2)
        });
      }

      if (this.formData.recycleCart96) {
        baseInfo.services.push({
          SERVGUID: generateGUID(),
          CM05_CUST: 26898,
          CM05_SITE: this.formData.siteNumber,
          CM05_TYPE: "RR",
          CM05_SIZE: 96.0,
          CURRENTSIZE: this.formData.currentRecycleCart || 96.0,
          CM05_QTY: 1,
          CM19_ACTV: "PS",
          CM19_RATE: (33.80 / 12).toFixed(2)
        });
      }

      return baseInfo;
    },

    handleClose() {
      this.$emit('close');
    },

    handleSuccessClose() {
      this.showSuccess = false;
      this.$emit('close');
    },

    handleErrorClose() {
      this.showError = false;
    },

    handleRetry() {
      this.showError = false;
      this.handleSubmit();
    },

    async handleSubmit() {
      this.loading = true;
      this.showError = false;

      try {
        this.logFormState();

        if (!process.env.VUE_APP_API_ENDPOINT) {
          throw new Error("API endpoint not configured");
        }

        // Validate cart selections
        const hasCartSelection =
          this.formData.wasteCart35 ||
          this.formData.wasteCart64 ||
          this.formData.wasteCart96 ||
          this.formData.recycleCart35 ||
          this.formData.recycleCart64 ||
          this.formData.recycleCart96;

        if (!hasCartSelection) {
          throw new Error("Please select at least one cart size before submitting.");
        }

        // Validate acknowledgements if needed
        if (this.needs96GallonAcknowledgement && !this.formData.UpsizeAck) {
          throw new Error("Please acknowledge the 96-gallon cart upsize rate.");
        }

        let apiSuccess = false;

        // Handle standard cart sizes (35/64 gallon)
        const confirmationPayloads = this.generateCartSizeConfirmation();

        if (confirmationPayloads.length > 0) {
          for (const payload of confirmationPayloads) {
            console.log('Submitting confirmation payload:', payload);
            const response = await fetch(process.env.VUE_APP_API_ENDPOINT, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(payload)
            });

            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(`Failed to submit confirmation payload: ${errorText}`);
            }
          }
          apiSuccess = true;
        }

        // Handle 96 gallon carts
        if (this.formData.wasteCart96 || this.formData.recycleCart96) {
          const servicePayload = this.generateServicePayload();
          console.log('Submitting 96-gallon service payload:', servicePayload);

          const response = await fetch(process.env.VUE_APP_API_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(servicePayload)
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to submit service payload: ${errorText}`);
          }
          apiSuccess = true;
        }

        if (apiSuccess) {
          await NotificationService.sendEmail(
            this.formData.email,
            "LRS Cart Size Confirmation",
            NotificationService.getFormSubmissionTemplate()
          );
          this.showSuccess = true;
        }

      } catch (error) {
        console.error("Submission error:", error);
        this.showError = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  padding: 1.5rem;
  overflow-y: auto;
}

.modal-footer {
  padding: 1rem;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.step {
  flex: 1;
  text-align: center;
  padding: 0.5rem;
  border-bottom: 3px solid #ddd;
}

.step.active {
  border-bottom-color: #0066cc;
  color: #0066cc;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0066cc;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
}

.button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button.primary {
  background: #0066cc;
  color: white;
}

.button.secondary {
  background: #6c757d;
  color: white;
}

.button.success {
  background: #28a745;
  color: white;
}
</style>