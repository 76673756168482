<template>
  <div class="email-input-container">
    <input type="email" :value="modelValue" @input="handleInput" @blur="validateOnBlur"
      :class="{ 'error': error, 'success': isValid }" :placeholder="placeholder" autocomplete="email" />

    <div class="validation-status" v-if="error || isChecking">
      <span v-if="isChecking" class="checking">
        
      </span>
      <span v-else-if="error" class="error-message">
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
import { isValidEmailFormat, validateEmailMX } from '@/utils/emailValidator';
import debounce from 'lodash/debounce';

export default {
  name: 'EmailInput',

  props: {
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Enter your email address'
    }
  },

  data() {
    return {
      error: '',
      isValid: false,
      isChecking: false
    }
  },

  created() {
    this.debouncedValidate = debounce(this.validateEmail, 500);
  },

  methods: {
    async validateEmail(email) {
      if (!email) {
        this.error = 'Email is required';
        this.isValid = false;
        this.$emit('validation', false);
        return;
      }

      if (!isValidEmailFormat(email)) {
        this.error = 'Invalid email format';
        this.isValid = false;
        this.$emit('validation', false);
        return;
      }

      this.isChecking = true;
      this.error = '';

      try {
        const result = await validateEmailMX(email);

        this.isValid = result.isValid;
        this.error = result.message;

        this.$emit('validation', result.isValid);

      } catch (err) {
        this.isValid = isValidEmailFormat(email);
        this.error = this.isValid ? '' : 'Invalid email format';
        this.$emit('validation', this.isValid);
      } finally {
        this.isChecking = false;
      }
    },

    handleInput(event) {
      const value = event.target.value;
      this.$emit('update:modelValue', value);

      // Do immediate format validation
      if (value) {
        const isValid = isValidEmailFormat(value);
        this.error = isValid ? '' : 'Invalid email format';
        this.isValid = isValid;
        this.isChecking = false; // Don't show checking on basic validation
        this.$emit('validation', isValid);
      } else {
        this.error = '';
        this.isValid = false;
        this.isChecking = false;
        this.$emit('validation', false);
      }
    },

    validateOnBlur() {
      if (this.modelValue && isValidEmailFormat(this.modelValue)) {
        // Only do MX validation if basic format is valid
        // and don't show checking message for quick validations
        this.debouncedValidate(this.modelValue);
      }
    },

    
  }
};
</script>

<style scoped>
.email-input-container {
  width: 100%;
  margin-bottom: 1rem;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

input.error {
  border-color: #dc3545;
}

input.success {
  border-color: #28a745;
}

.validation-status {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}

.checking {
  color: #666;
  font-style: italic;
}

.error-message {
  color: #dc3545;
}
</style>