<template>
    <div class="update-customer-request">
      <h2>Update Customer Requests</h2>
  
      <!-- Search Address Section -->
      <SearchAddressLookup @address-selected="onAddressSelected" />
  
      <!-- Address Details -->
      <div v-if="address">
        <h3>Selected Address</h3>
        <p>{{ address.display }}</p>
      </div>
  
      <!-- Request Data Grid -->
      <div v-if="customerRequests.length">
        <h3>Customer Requests</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Request About</th>
              <th>Request Type</th>
              <th>Quantity</th>
              <th>Size</th>
              <th>Billing Cost</th>
              <th>Activity Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(request, index) in customerRequests" :key="request.ItemInternalId">
              <td>{{ request.SourceFormName }}</td>
              <td>
                <select v-model="request.ServiceTypeCode" class="form-select">
                  <option value="RG">Waste</option>
                  <option value="RR">Recycling</option>
                  <option value="RY">YardWaste</option>
                </select>
              </td>
              <td>
                <input type="number" v-model.number="request.ServiceQuantity" class="form-control" />
              </td>
              <td>
                <input type="number" v-model.number="request.ServiceSizeAfter" class="form-control" />
              </td>
              <td>
                {{ request.ServiceActivityRate ? `$${request.ServiceActivityRate.toFixed(2)}` : 'N/A' }}
              </td>
              <td>
                {{ getActivityDescription(request.ServiceActivityCode) }}
              </td>
              <td>
                <button class="btn btn-primary me-2" @click="updateRequest(index)">Save</button>
                <button class="btn btn-outline-danger" @click="removeRequest(index)">Remove</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- No Data Message -->
      <div v-else>
        <p>No requests found for this address.</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import SearchAddressLookup from "./SearchAddressLookup.vue";
  
  export default {
    name: "UpdateCustomerRequest",
    components: {
      SearchAddressLookup,
    },
    data() {
      return {
        address: null,
        customerRequests: [],
      };
    },
    methods: {
      onAddressSelected(address) {
        this.address = address;
        this.fetchCustomerRequests(address.siteNumber);
      },
      async fetchCustomerRequests(siteNumber) {
        const payload = {
          ROUTING_SITE: siteNumber,
          FORMTITLE: "Customer Service Updates",
        };
  
        try {
          const response = await axios.post(
            "https://la-trux.azurewebsites.net:443/api/wf-CheckSiteAlreadyOrdered/triggers/Check_User_by_Site_/invoke?api-version=2022-05-01&sp=%2Ftriggers%2FCheck_User_by_Site_%2Frun&sv=1.0&sig=6T41K4hvy_fKjhU5hcKBAZETSY0V-JneX5YQLxO4QV4",
            payload
          );
          this.customerRequests = this.filterRequests(response.data);
        } catch (error) {
          console.error("Error fetching customer requests:", error);
        }
      },
      filterRequests(requests) {
        const upsizeRequestedIds = requests
          .filter(request => request.ServiceActivityCode === 'PS')
          .map(request => request.RoutingSiteID);
  
        // Group requests by SourceFormName and find the max RecordTimestamp
        const groupedRequests = requests.reduce((acc, request) => {
          if (request.SourceFormName === 'Yard Waste Cart Request') {
            const existing = acc[request.SourceFormName];
            if (!existing || new Date(request.RecordTimestamp) > new Date(existing.RecordTimestamp)) {
              acc[request.SourceFormName] = request;
            }
          } else if (request.SourceFormName === 'Cart Size Confirmation Only') {
            const key = `${request.SourceFormName}-${request.ServiceTypeCode}`;
            const existing = acc[key];
            if (!existing || new Date(request.RecordTimestamp) > new Date(existing.RecordTimestamp)) {
              acc[key] = request;
            }
          } else {
            acc[request.ItemInternalId] = request;
          }
          return acc;
        }, {});
  
        return Object.values(groupedRequests).filter(request => {
          // Exclude "Cart Size Change Request" where ServiceActivityCode is null or undefined if an "Upsize Requested" exists for the same RoutingSiteID
          if (
            request.SourceFormName === 'Cart Size Change Request' &&
            (!request.ServiceActivityCode || request.ServiceActivityCode === 'Unknown Activity') &&
            upsizeRequestedIds.includes(request.RoutingSiteID)
          ) {
            return false;
          }
          return true;
        });
      },
      async updateRequest(index) {
        const updatedRequest = this.customerRequests[index];
        try {
          // Call to update the request in the backend
          await axios.post("/api/updateRequest", updatedRequest);
          alert("Request updated successfully.");
        } catch (error) {
          console.error("Error updating request:", error);
        }
      },
      removeRequest(index) {
        this.customerRequests.splice(index, 1);
      },
      getActivityDescription(activityCode) {
        if (activityCode === 'PS') return 'Upsize Requested';
        if (activityCode === 'AT') return 'Additional Cart';
        return 'Unknown Activity';
      },
    },
  };
  </script>
  
  <style scoped>
  .update-customer-request {
    margin: 20px;
  }
  
  .table {
    margin-top: 20px;
  }
  
  h2, h3 {
    margin-bottom: 10px;
  }
  
  .btn {
    padding: 5px 10px;
    font-size: 14px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    background-color: #dc3545;
    color: #fff;
  }
  </style>
  