<template>
  <div>
    <!-- Initial Additional Cart Section -->
    <div class="form-group">
      <h2 class="subheading-1">Additional Cart Enrollment</h2>

      <p class="paragraph">
        LRS offers additional carts in 64 & 96-gallon sizes for waste, recyclables, yard waste and organics to rent.
        Maximum 2 carts per type.
      </p>

      <!-- Toggle switch for additional cart -->
      <div class="toggle-container">
        <label class="toggle-label">
          <span><strong>Additional Cart Request</strong></span>
          <div class="toggle-switch">
            <input type="checkbox" :checked="form.additional === 'yes'"
              @change="emitUpdate({ additional: $event.target.checked ? 'yes' : 'no' })">
            <span class="slider"></span>
          </div>
        </label>
        <small>Select if you'd like to request additional carts</small>
      </div>
    </div>

    <!-- Additional Cart Details Section -->
    <div v-if="form.additional === 'yes'" class="form-group">
      <p class="paragraph">
        Additional carts are billed annually in advance. Your first charge will be based on the delivery date.
        Cancellation requests must be received by December 1st to avoid recurring charges.
      </p>

      <div class="cart-rates-info">
        <div class="rate-card">
          <span class="rate-size">64-Gallon</span>
          <span class="rate-price">$191.40/year</span>
        </div>
        <div class="rate-card">
          <span class="rate-size">96-Gallon</span>
          <span class="rate-price">$225.00/year</span>
        </div>
      </div>

      <div class="cart-selection-grid">
        <!-- Waste Carts -->
        <div class="cart-type-section">
          <h3>Waste Carts ({{ wasteTotal }}/2)</h3>
          <div class="cart-input-group">
            <label>
              <span>64-Gallon</span>
              <input :value="form.AddTr64" @input="handleInput('AddTr64', $event.target.value, 'waste')" type="number"
                min="0" :max="2 - Number(form.AddTr96 || 0)" placeholder="0" />
            </label>
            <div class="cart-total">${{ calculateItemTotal(form.AddTr64, 191.40).toFixed(2) }}</div>
          </div>
          <div class="cart-input-group">
            <label>
              <span>96-Gallon</span>
              <input :value="form.AddTr96" @input="handleInput('AddTr96', $event.target.value, 'waste')" type="number"
                min="0" :max="2 - Number(form.AddTr64 || 0)" placeholder="0" />
            </label>
            <div class="cart-total">${{ calculateItemTotal(form.AddTr96, 225.00).toFixed(2) }}</div>
          </div>
        </div>

        <!-- Recycle Carts -->
        <div class="cart-type-section">
          <h3>Recycle Carts ({{ recycleTotal }}/2)</h3>
          <div class="cart-input-group">
            <label>
              <span>64-Gallon</span>
              <input :value="form.AddRec64" @input="handleInput('AddRec64', $event.target.value, 'recycle')"
                type="number" min="0" :max="2 - Number(form.AddRec96 || 0)" placeholder="0" />
            </label>
            <div class="cart-total">${{ calculateItemTotal(form.AddRec64, 191.40).toFixed(2) }}</div>
          </div>
          <div class="cart-input-group">
            <label>
              <span>96-Gallon</span>
              <input :value="form.AddRec96" @input="handleInput('AddRec96', $event.target.value, 'recycle')"
                type="number" min="0" :max="2 - Number(form.AddRec64 || 0)" placeholder="0" />
            </label>
            <div class="cart-total">${{ calculateItemTotal(form.AddRec96, 225.00).toFixed(2) }}</div>
          </div>
        </div>

        <!-- Yard Waste Carts -->
        <div class="cart-type-section">
          <h3>Yard Waste Carts</h3>
                    <!-- Cart Size Confirmation Section -->
                    <div class="cart-confirmation-section">
          <h3a class="subheading-1">
            Have you requested your free cart?
          </h3a>
	<button class="lrs-button" style="width: 90%;" onclick="window.location.href='/form/yard-waste';">If Not, Click Here to Request Free Optional Organics & Yard Waste Cart</button>
        </div>
          <div class="cart-input-group">
            <label>
              <span>64-Gallon</span>
              <input :value="form.AddYr64" @input="handleInput('AddYr64', $event.target.value, 'yard')" type="number"
                min="0" :max="2 - Number(form.AddYr96 || 0)" placeholder="0" />
            </label>
            <div class="cart-total">${{ calculateItemTotal(form.AddYr64, 191.40).toFixed(2) }}</div>
          </div>
          <div class="cart-input-group">
            <label>
              <span>96-Gallon</span>
              <input :value="form.AddYr96" @input="handleInput('AddYr96', $event.target.value, 'yard')" type="number"
                min="0" :max="2 - Number(form.AddYr64 || 0)" placeholder="0" />
            </label>
            <div class="cart-total">${{ calculateItemTotal(form.AddYr96, 225.00).toFixed(2) }}</div>
          </div>
        </div>
      </div>

      <!-- Validation message -->
      <div v-if="!hasAdditionalCart" class="validation-message">
        Please select at least one additional cart to continue or deselect this option if you don't need additional
        carts.
      </div>

      <div class="total-summary">
        <span>Total Annual Cost:</span>
        <strong>${{ totalCost.toFixed(2) }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step4AdditionalCarts',
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasAdditionalCart() {
      if (this.form.additional !== 'yes') return true;

      return (
        Number(this.form.AddTr64) > 0 ||
        Number(this.form.AddTr96) > 0 ||
        Number(this.form.AddRec64) > 0 ||
        Number(this.form.AddRec96) > 0 ||
        Number(this.form.AddYr64) > 0 ||
        Number(this.form.AddYr96) > 0
      );
    },
    totalCost() {
      return (
        this.calculateItemTotal(this.form.AddTr64, 191.4) +
        this.calculateItemTotal(this.form.AddTr96, 225.0) +
        this.calculateItemTotal(this.form.AddRec64, 191.4) +
        this.calculateItemTotal(this.form.AddRec96, 225.0) +
        this.calculateItemTotal(this.form.AddYr64, 191.4) +
        this.calculateItemTotal(this.form.AddYr96, 225.0)
      );
    },
    wasteTotal() {
      return Number(this.form.AddTr64 || 0) + Number(this.form.AddTr96 || 0);
    },
    recycleTotal() {
      return Number(this.form.AddRec64 || 0) + Number(this.form.AddRec96 || 0);
    },
    yardTotal() {
      return Number(this.form.AddYr64 || 0) + Number(this.form.AddYr96 || 0);
    }
  },
  methods: {
    emitUpdate(updates) {
      this.$emit('update-form', { ...this.form, ...updates });
    },
    calculateItemTotal(quantity, costPerItem) {
      return Number(quantity || 0) * costPerItem;
    },
    handleInput(field, value, type) {
      let adjustedValue = Math.max(0, Number(value));
      let otherFieldValue;

      switch (type) {
        case 'waste':
          otherFieldValue = field === 'AddTr64' ? this.form.AddTr96 : this.form.AddTr64;
          if (adjustedValue + Number(otherFieldValue || 0) > 2) {
            adjustedValue = Math.max(0, 2 - Number(otherFieldValue || 0));
          }
          break;
        case 'recycle':
          otherFieldValue = field === 'AddRec64' ? this.form.AddRec96 : this.form.AddRec64;
          if (adjustedValue + Number(otherFieldValue || 0) > 2) {
            adjustedValue = Math.max(0, 2 - Number(otherFieldValue || 0));
          }
          break;
        case 'yard':
          otherFieldValue = field === 'AddYr64' ? this.form.AddYr96 : this.form.AddYr64;
          if (adjustedValue + Number(otherFieldValue || 0) > 2) {
            adjustedValue = Math.max(0, 2 - Number(otherFieldValue || 0));
          }
          break;
      }

      this.emitUpdate({ [field]: adjustedValue });
    }
  }
};
</script>

<style scoped>
.cart-selection-grid {
  display: grid;
  gap: 2rem;
  margin: 2rem 0;
}

.cart-type-section {
  background: #f8f9fa;
  padding: 0.1rem;
  border-radius: 8px;
}

.cart-input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;
}

.cart-input-group label {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.cart-input-group input {
  width: 80px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cart-total {
  min-width: 100px;
  text-align: right;
  font-weight: bold;
}

.cart-confirmation-section {
  margin: 2rem 0;
  text-align: center;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 2px solid var(--lrs-blue);
}

.total-summary {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 2px solid #ddd;
  font-size: 1.2rem;
}

.total-summary strong {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.cart-rates-info {
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
}

.rate-card {
  background: white;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rate-size {
  font-weight: bold;
  color: var(--primary-color);
}

.validation-message {
  color: var(--error-color);
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .cart-selection-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
</style>