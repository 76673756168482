<template>
    <div v-if="isOpen" class="modal-overlay">
        <div class="modal-content">
            <header class="modal-header">
                <h2 class="modal-title">Existing Orders Found</h2>
                <p class="modal-description">We found the following existing orders for this address:</p>
            </header>

            <div class="orders-container">
                <div v-for="order in orders" :key="order.ItemInternalId" class="order-card">
                    <div class="order-grid">
                        <div class="order-detail">
                            <span class="detail-label">Service Type</span>
                            <p class="detail-value">{{ getServiceType(order.ServiceTypeCode, order.ServiceActivityCode) }}</p>
                        </div>
                        <div class="order-detail">
                            <span class="detail-label">Size</span>
                            <p class="detail-value">{{ order.ServiceSizeAfter }} Gallon</p>
                        </div>
                        <div class="order-detail">
                            <span class="detail-label">Quantity</span>
                            <p class="detail-value">{{ order.ServiceQuantity }}</p>
                        </div>
                        <div class="order-detail">
                            <span class="detail-label">Rate</span>
                            <p class="detail-value">${{ order.ServiceActivityRate }}/month</p>
                        </div>
                    </div>
                    <div class="order-date">
                        Ordered on {{ formatDate(order.RecordTimestamp) }}
                    </div>
                </div>
            </div>

            <div class="contact-info">
                <p>
                    If you need to make changes to your existing orders, please contact us at
                    <a href="tel:855-612-7938">855.612.7938</a> or
                    <a href="mailto:elginservice@lrsrecycles.com">ElginService@LRSrecycles.com</a>
                </p>
            </div>

            <footer class="modal-footer">
                <button @click="$emit('close')" class="btn-secondary">Cancel Order</button>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddressValidationModal',
    props: {
        isOpen: {
            type: Boolean,
            required: true
        },
        orders: {
            type: Array,
            required: true
        }
    },
    methods: {
        getServiceType(type, activity) {
            // Base service types
            const types = {
                'RG': 'Waste',
                'RY': 'Yard Waste',
                'RR': 'Recycling'
            };

            // Get the base service name
            const baseType = types[type] || type;

            // Add prefix based on activity
            if (activity === 'AT') {
                return `Additional Cart -  ${baseType}`;
            } else if (activity === 'PS' || activity === 'TQ') {
                return `Size Change - ${baseType}`;
            }

            return baseType;
        },
        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        }
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 60px;
    /* Align with header */
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 9999;
}

.modal-content {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.modal-header {
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    flex-shrink: 0;
}

.modal-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #005cb2;
    margin: 0 0 0.5rem 0;
}

.modal-description {
    color: #666;
    margin: 0;
    font-size: 0.9rem;
}

.orders-container {
    flex: 1;
    overflow-y: auto;
    padding: 0;
    background-color: #ffffff;
}

.order-card {
    background: white;
    border-bottom: 1px solid #e9ecef;
    padding: 0.5rem 1rem;
    /* Reduced padding */
    margin: 0;
}

.order-grid {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    /* Reduced gap */
}

.order-detail {
    display: flex;
    flex-direction: row;
    /* Changed to row */
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.detail-label {
    font-size: 0.8rem;
    color: #666;
    margin: 0;
    min-width: 80px;
    /* Added min-width for alignment */
}

.detail-value {
    font-size: 0.9rem;
    font-weight: 500;
    color: #333;
    margin: 0;
    text-align: right;
}

.order-date {
    margin-top: 0.25rem;
    /* Reduced margin */
    font-size: 0.75rem;
    color: #666;
    padding-top: 0.25rem;
    border-top: 1px solid #f0f0f0;
}

.contact-info {
    background: #e8f4fd;
    padding: 0.75rem 1rem;
    flex-shrink: 0;
}

.contact-info p {
    margin: 0;
    font-size: 0.85rem;
    line-height: 1.4;
}

.contact-info a {
    color: #005cb2;
    font-weight: 600;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

.modal-footer {
    padding: 0.75rem 1rem;
    background: #e8f4fd;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: sticky;
    bottom: 0;
}

.btn-secondary {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    background-color: white;
    color: #333;
    border: 1px solid #dee2e6;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.btn-secondary:hover {
    background-color: #f8f9fa;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 640px) {
    .modal-content {
        width: 95%;
        margin: 5px;
    }

    .modal-header,
    .orders-container,
    .contact-info,
    .modal-footer {
        padding: 0.75rem;
    }
}
</style>
