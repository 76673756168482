<template>
    <br>
    <h2 class="h2">Organics and Yard Waste Sign-up</h2><br>

    <div class="step-form">
        <h3 class="step-title">Step {{ step }} of 3</h3>

        <keep-alive>
            <component :is="currentStepComponent" :form="form" @update-form="updateForm" />
        </keep-alive>

        <div class="form-actions">
            <div class="container">
                <button v-if="step !== 1" @click="prevStep" class="button secondary">
                    Previous
                </button>&nbsp;&nbsp;
                <button v-if="step < 3" @click="nextStep" :disabled="!isStepValid" class="button primary">
                    Next
                </button>&nbsp;&nbsp;
                <button v-if="step === 3" @click="submitForm" :disabled="!isContactValid" class="button success"
                    type="button">
                    Submit
                </button>
            </div>
        </div>

        <!-- Status Components -->
        <loading-overlay :show="loading" />
        <submission-status :show="showSuccess" @close="handleSuccessClose" />
        <error-status :show="showError" @close="handleErrorClose" @retry="handleRetry" />
    </div>
</template>

<script>
import Step1AddressInformation from '@/components/Steps/Step2AddressInformation.vue';
import Step2YardWasteSelection from '@/components/YardWasteSteps/Step2YardWasteSelection.vue';
import Step3ContactInformation from '@/components/Steps/Step1ContactInformation.vue';
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import SubmissionStatus from "@/components/SubmissionStatus.vue";
import ErrorStatus from "@/components/ErrorStatus.vue";
import { NotificationService } from '@/utils/notificationService';
const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const generateGUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export default {
    name: 'YardWasteForm',
    components: {
        Step1AddressInformation,
        Step2YardWasteSelection,
        Step3ContactInformation,
        LoadingOverlay,
        SubmissionStatus,
        ErrorStatus
    },
    data() {
        return {
            step: 1,
            loading: false,
            showSuccess: false,
            showError: false,
            form: {
                // Contact Information
                title: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneType: 'mobile',
                phoneNumber: "",
                isEmailValid: false,
                serviceupdate: "yes",
                marketing: "yes",
                contactMethod: [],

                // Address Information
                address: "",
                address1: "",
                address2: "",
                city: "ELGIN",
                state: "IL",
                zip: "",
                county: "",
                siteNumber: null,

                // Yard Waste Cart Selection
                requestedYardCart: "35-Gallon",

                completedBy: "Resident"
            }
        };
    },
    computed: {
        currentStepComponent() {
            switch (this.step) {
                case 1: return 'Step1AddressInformation';
                case 2: return 'Step2YardWasteSelection';
                case 3: return 'Step3ContactInformation';
                default: return 'Step1AddressInformation';
            }
        },
        isStepValid() {
            switch (this.step) {
                case 1: return this.isAddressValid;
                case 2: return this.form.requestedYardCart !== "No Cart Needed";
                case 3: return this.isContactValid;
                default: return true;
            }
        },
        isAddressValid() {
            return Boolean(
                this.form.address1 &&
                this.form.city &&
                this.form.state &&
                this.form.zip &&
                this.form.siteNumber
            );
        },
        isContactValid() {
            // Strict phone validation
            const phoneDigits = this.form.phoneNumber?.replace(/\D/g, '') || '';
            const isPhoneValid = phoneDigits.length === 10;

            return Boolean(
                this.form.firstName?.trim() &&
                this.form.lastName?.trim() &&
                this.form.email &&
                this.form.isEmailValid && // Email validation state
                isPhoneValid &&
                this.form.contactMethod.length > 0  // Phone must be exactly 10 digits
            );
        }
    },
    methods: {
        updateForm(updates) {
            this.form = { ...this.form, ...updates };
        },
        nextStep() {
            if (this.isStepValid && this.step < 3) this.step++;
        },
        prevStep() {
            if (this.step > 1) this.step--;
        },
        generatePayload() {
            if (!this.form.siteNumber) {
                throw new Error('Site number is required');
            }

            const size = parseInt(this.form.requestedYardCart);
            if (!size) {
                throw new Error('Valid cart size selection is required');
            }

            return {
                SITEGUID: generateGUID(),
                FORMTITLE: "Yard Waste Cart Request",
                SourceFormName: "Yard Waste Cart Request",

                // Required fields for stored procedure
                CM05_CUST: 26898,
                CM05_SITE: parseInt(this.form.siteNumber),
                CM05_TYPE: "RY",
                CM05_SIZE: size,
                CURRENTSIZE: 32.00, // Yard waste doesn't need current size

                // Contact Information
                CC01_SALU: this.form.title || '',
                CC01_FNAME: this.form.firstName.toUpperCase(),
                CC01_LNAME: this.form.lastName.toUpperCase(),
                CC01_PHONETYPE: this.form.phoneType,
                CC01_PHONE: this.form.phoneNumber,
                CC01_EMAIL: this.form.email,

                // Address Information
                CM04_NAME: `${this.form.firstName} ${this.form.lastName}`.toUpperCase(),
                CM04_FLD3: this.form.county,
                CM04_ADD2: this.form.address1,
                CM04_ADD3: this.form.address2 || "",
                CM04_CITY: this.form.city,
                CM04_STAT: this.form.state,
                CM04_PZIP: this.form.zip,
                CM04_ISMAILSAME: true,

                // Flags and Preferences
                SERVICE_UPDATES_FLAG: this.form.serviceupdate === "yes" ? 1 : 0,
                MARKETING_UPDATES_FLAG: this.form.marketing === "yes" ? 1 : 0,
                PREFERRED_CONTACT_METHOD: this.form.contactMethod.join(","),
                FORM_COMPLETED_BY: this.form.completedBy,

                // Additional metadata
                ROUTING_SITE: this.form.siteNumber,
                ROUTING_CUST: 26898
            };
        },
        async submitForm() {
            this.loading = true;
            this.showError = false;

            try {
                const payload = this.generatePayload();
                console.log('Submitting payload:', payload);

                const response = await fetch(
                    API_ENDPOINT,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(payload),
                    }
                );

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Server Error: ${errorText}`);
                }

                await NotificationService.sendEmail(
                    this.form.email,
                    "LRS Cart Request Confirmation",
                    NotificationService.getFormSubmissionTemplate()
                );

                this.loading = false;
                this.showSuccess = true;

            } catch (error) {
                console.error("Error submitting form:", error);
                this.loading = false;
                this.showError = true;
            }
        },
        handleSuccessClose() {
            this.showSuccess = false;
            this.$router.push('/');
        },
        handleErrorClose() {
            this.showError = false;
        },
        handleRetry() {
            this.showError = false;
            this.submitForm();
        }
    }
};
</script>

<style src="./StepForm.css"></style>