<template>
  <div class="elgin-resident-portal">



<!-- Section 1 -->
<section class="responsive-section">
  <div class="column column-1">
    <!-- Column 1 Content -->
<br>
<h1 class="h1"> Missed Pickup</h1><br>


<div class="container">
  <!-- Left Column -->
  <div class="contact-info">
  <p class="paragraph">
    Was your waste, recycling or yard waste not collected on your service day?  Contact us today and our team will look into it and schedule a recovery pick up for you.
      </p> <br>
      <button
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('tel:855-612-7938');">
            By Phone at<br>855.612.7938
        </button><br><br>            

        <button 
            class="lrs-button" 
            style="width: 275px;" 
            onclick="window.open('mailto:elginservice@lrsrecycles.com', '_blank');">
            By Email at<br>ElginService@LRSrecycles.com
        </button><br><br>


  </div>

  <!-- Right Column -->

    <div class="contact-image">
    <img src="@\assets\pickupmap.png" alt="Elgin Route Map" class="responsive-image">
  </div>
  </div>


  </div>
  </section>


  </div>
</template>

<style scoped>
/* Responsive Column Adjustments */
@media (max-width: 768px) {

.column-1,
.column-2,
.column-3,
.column-4,
.column-5 {
  flex: 1 1 100%;
  text-align: left;
  /* Adjust text alignment for mobile */
}
.h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 28px;
  color: var(--lrs-blue);
}

.h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: var(--recycle-green);
  margin-top: 10px;
  margin-bottom: 10px;
}
.subheading-1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: var(--safety-orange);
}
.h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--recycle-green);
}
}

/* Media Queries for Smaller Devices */
@media (max-width: 768px) {

  .section-1,
  .section-3 {
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%;
  }

  .card {
    width: 90%;
    margin: 10px 0;
  }

  .elgin-resident-portal {
    padding: 10px;
  }
}

</style>
