<template>
  <div class="review-submit">
    <!-- Same template structure as before, but using localForm instead of form -->
    <div class="card">
      <h3 class="section-title">Service Address</h3>
      <div class="content">
        <p><strong>Address:</strong> {{ localForm.address1 }}</p>
        <p v-if="localForm.address2"><strong>Address 2:</strong> {{ localForm.address2 }}</p>
        <p><strong>City:</strong> {{ localForm.city }}</p>
        <p><strong>State:</strong> {{ localForm.state }}</p>
        <p><strong>ZIP:</strong> {{ localForm.zip }}</p>
      </div>
    </div>

    <!-- Cart Selection -->
    <div class="card">
      <h3 class="section-title">Cart Selection</h3>
      <div class="content">
        <p><strong>Waste Cart Size:</strong> {{ localForm.requestedWasteCart }}</p>
        <p><strong>Recycle Cart Size:</strong> {{ localForm.requestedRecycleCart }}</p>
        <p><strong>Yard Waste Cart Size:</strong> {{ localForm.requestedYardCart }}</p>
      </div>
    </div>

    <!-- Additional Cart Summary Table -->
    <!-- Replace the table section with this -->
    <div v-if="hasAdditionalCart" class="card">
      <h3 class="section-title">Additional Carts Summary</h3>

      <!-- Cart Summary Items -->
      <div class="cart-summary">
        <!-- Waste Carts -->
        <div v-if="localForm.AddTr64 > 0" class="summary-item">
          <div class="item-details">
            <div class="item-name">WASTE/64-Gallon</div>
            <div class="item-quantity">{{ localForm.AddTr64 }}</div>
          </div>
          <div class="item-cost">${{ calculateItemTotal(localForm.AddTr64, 191.40).toFixed(2) }}</div>
        </div>

        <div v-if="localForm.AddTr96 > 0" class="summary-item">
          <div class="item-details">
            <div class="item-name">WASTE/96-Gallon</div>
            <div class="item-quantity">{{ localForm.AddTr96 }}</div>
          </div>
          <div class="item-cost">${{ calculateItemTotal(localForm.AddTr96, 225.00).toFixed(2) }}</div>
        </div>

        <!-- Recycle Carts -->
        <div v-if="localForm.AddRec64 > 0" class="summary-item">
          <div class="item-details">
            <div class="item-name">RECYCLE/64-Gallon</div>
            <div class="item-quantity">{{ localForm.AddRec64 }}</div>
          </div>
          <div class="item-cost">${{ calculateItemTotal(localForm.AddRec64, 191.40).toFixed(2) }}</div>
        </div>

        <div v-if="localForm.AddRec96 > 0" class="summary-item">
          <div class="item-details">
            <div class="item-name">RECYCLE/96-Gallon</div>
            <div class="item-quantity">{{ localForm.AddRec96 }}</div>
          </div>
          <div class="item-cost">${{ calculateItemTotal(localForm.AddRec96, 225.00).toFixed(2) }}</div>
        </div>

        <!-- Yard Waste Carts -->
        <div v-if="localForm.AddYr64 > 0" class="summary-item">
          <div class="item-details">
            <div class="item-name">YARD/64-Gallon</div>
            <div class="item-quantity">{{ localForm.AddYr64 }}</div>
          </div>
          <div class="item-cost">${{ calculateItemTotal(localForm.AddYr64, 191.40).toFixed(2) }}</div>
        </div>

        <div v-if="localForm.AddYr96 > 0" class="summary-item">
          <div class="item-details">
            <div class="item-name">YARD/96-Gallon</div>
            <div class="item-quantity">{{ localForm.AddYr96 }}</div>
          </div>
          <div class="item-cost">${{ calculateItemTotal(localForm.AddYr96, 225.00).toFixed(2) }}</div>
        </div>

        <!-- Total -->
        <div class="summary-total">
          <div class="total-label">Total Annual Cost:</div>
          <div class="total-amount">${{ totalAnnualCost.toFixed(2) }}</div>
        </div>
      </div>
    </div>

    
    <!-- Acknowledgements -->
    <div class="card acknowledgements">
      <h3 class="section-title highlight">Required Acknowledgements</h3>

      <!-- 96-Gallon Cart Acknowledgement -->
      <div v-if="showUpsizeAcknowledgement" class="acknowledgement-item">
        <div class="acknowledgement-content">
          <h4>96-Gallon Cart Upsize Rate Acknowledgement</h4>
          <p>
            I understand that requesting a 96-gallon Waste Cart will result in an annual fee of $33.60,
            paid in advance. No credits or refunds will be issued for early termination.
          </p>
          <label class="checkbox-container">
            <input type="checkbox" :checked="localForm.UpsizeAck === 'I Accept'"
              @change="updateField('UpsizeAck', $event.target.checked ? 'I Accept' : '')">
            <span class="checkmark"></span>
            <span class="checkbox-label">I Accept</span>
          </label>


        </div>
      </div>

      <!-- Annual Renewal Acknowledgement -->
      <div class="acknowledgement-item">
        <div class="acknowledgement-content">
          <h4>Annual Renewal & Billing Acknowledgement</h4>
          <p>
            I acknowledge that I will receive an annual invoice and there will be no refunds
            for early termination.
          </p>
          <label class="checkbox-container">
            <input type="checkbox" :checked="localForm.RenewalAck === 'I Accept'"
              @change="updateField('RenewalAck', $event.target.checked ? 'I Accept' : '')">
              <span class="checkmark"></span>
            <span class="checkbox-label">I Accept</span>
          </label>
        </div>
      </div>
    </div>

    <!-- Billing Information -->
    <div class="card">
      <h3 class="section-title">Billing Information</h3>
      <ToggleSwitch label="Different Billing Address" hint="Check if billing address differs from service address"
        :model-value="localForm.BillingDifferent === 'Billing Different'"
        @update:model-value="updateBillingDifferent" />

      <div v-if="localForm.BillingDifferent === 'Billing Different'" class="billing-form">
        <div class="form-grid">
          <div class="form-group">
            <label for="billingattention">Attention To:</label>
            <input :value="localForm.billingattention" @input="updateField('billingattention', $event.target.value)"
              type="text" id="billingattention" class="form-input">
          </div>
          <div class="form-group">
            <label for="billingaddress1">Address 1:</label>
            <input :value="localForm.billingaddress1" @input="updateField('billingaddress1', $event.target.value)"
              type="text" id="billingaddress1" class="form-input">
          </div>
          <div class="form-group">
            <label for="billingaddress2">Address 2:</label>
            <input :value="localForm.billingaddress2" @input="updateField('billingaddress2', $event.target.value)"
              type="text" id="billingaddress2" class="form-input">
          </div>
          <div class="form-group">
            <label for="billingcity">City:</label>
            <input :value="localForm.billingcity" @input="updateField('billingcity', $event.target.value)" type="text"
              id="billingcity" class="form-input">
          </div>
          <div class="form-group">
            <label for="billingstate">State:</label>
            <input :value="localForm.billingstate" @input="updateField('billingstate', $event.target.value)" type="text"
              id="billingstate" class="form-input">
          </div>
          <div class="form-group">
            <label for="billingzip">Zip Code:</label>
            <input :value="localForm.billingzip" @input="updateField('billingzip', $event.target.value)" type="text"
              id="billingzip" class="form-input">
          </div>
        </div>

      </div>
    </div>

    <!-- Completed By -->
    <div class="card">
      <h3 class="section-title">Completed By</h3>
      <div class="button-group">
        <button v-for="option in completedByOptions" :key="option"
          :class="['option-button', { active: localForm.completedBy === option }]"
          @click="updateField('completedBy', option)">
          {{ option }}
        </button>
      </div>
    </div>

    <!-- Validation Message -->
    <div v-if="!isValid" class="validation-message">
      <p>Please complete all required acknowledgements before proceeding</p>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '../ToggleSwitch.vue'

export default {
  name: 'Step5ReviewSubmit',
  components: {
    ToggleSwitch
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      completedByOptions: ['Resident', 'LRS Customer Service', 'Elgin Neighborhood Services'],
      localForm: { ...this.form } // Create local copy of the form
    }
  },
  // In Step5ReviewSubmit.vue, add this watch
  watch: {
    isValid: {
      immediate: true,
      handler(newValue) {
        this.$emit('update-form', { step5Valid: newValue });
      }
    }
  },
  computed: {
    showUpsizeAcknowledgement() {
      return this.localForm.requestedWasteCart === "96-Gallon" ||
        this.localForm.requestedRecycleCart === "96-Gallon"
    }, hasAdditionalCart() {
      return (
        Number(this.localForm.AddTr64) > 0 ||
        Number(this.localForm.AddTr96) > 0 ||
        Number(this.localForm.AddRec64) > 0 ||
        Number(this.localForm.AddRec96) > 0 ||
        Number(this.localForm.AddYr64) > 0 ||
        Number(this.localForm.AddYr96) > 0
      );
    },
    totalAnnualCost() {
      return (
        this.calculateItemTotal(this.localForm.AddTr64, 191.40) +
        this.calculateItemTotal(this.localForm.AddTr96, 225.00) +
        this.calculateItemTotal(this.localForm.AddRec64, 191.40) +
        this.calculateItemTotal(this.localForm.AddRec96, 225.00) +
        this.calculateItemTotal(this.localForm.AddYr64, 191.40) +
        this.calculateItemTotal(this.localForm.AddYr96, 225.00)
      );
    },
    isValid() {
      if (this.showUpsizeAcknowledgement && this.localForm.UpsizeAck !== 'I Accept') return false
      if (this.localForm.RenewalAck !== 'I Accept') return false
      if (this.localForm.BillingDifferent === 'Billing Different') {
        return Boolean(
          this.localForm.billingattention &&
          this.localForm.billingaddress1 &&
          this.localForm.billingcity &&
          this.localForm.billingstate &&
          this.localForm.billingzip
        )
      }
      return true
    }
  },
  created() {
    // Set default completed by if not set
    if (!this.localForm.completedBy) {
      this.updateField('completedBy', 'Resident')
    }
  },
  methods: {
    calculateItemTotal(quantity, cost) {
      return Number(quantity || 0) * cost;
    },
    updateField(field, value) {
      // Update local form first
      this.localForm[field] = value
      // Emit the change to parent
      this.$emit('update-form', { [field]: value })
    },
    updateBillingDifferent(value) {
      this.updateField('BillingDifferent', value ? 'Billing Different' : '')
    }
  }
}
</script>

<style scoped>
.review-submit {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  background: white;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--lrs-blue);
  margin-bottom: 16px;
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--lrs-blue);
  margin-bottom: 16px;
}

.section-title.highlight {
  color: var(--recycle-green);
}

.intro-text {
  color: #64748b;
  line-height: 1.6;
}

.link {
  color: #2563eb;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
}

.label-text {
  font-weight: 500;
}

/* Cart Summary Styles */
.cart-summary {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e2e8f0;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
}

.summary-item:hover {
  background-color: #f8fafc;
}

.item-details {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1;
}

.item-name {
  font-weight: 500;
  color: #1e293b;
  min-width: 180px;
}

.item-quantity {
  color: #64748b;
  font-weight: 500;
  background: #f1f5f9;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.9rem;
}

.item-cost {
  font-weight: 600;
  color: #1e293b;
}

.summary-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  background: #f8fafc;
  border-top: 2px solid #e2e8f0;
}

.total-label {
  font-weight: 600;
  color: #1e293b;
}

.total-amount {
  font-weight: 700;
  color: #1e40af;
  font-size: 1.1rem;
}

.acknowledgements {
  border: 2px solid #bfdbfe;
  background-color: #f8fafc;
}

.acknowledgement-item {
  padding: 16px;
  margin-bottom: 16px;
  background: white;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
}

.acknowledgement-content h4 {
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--lrs-blue);
}

.acknowledgement-content p {
  color: #64748b;
  margin-bottom: 16px;
  line-height: 1.6;
}

.checkbox-container {
  display: flex; /* Align checkbox and label text side by side */
  align-items: center; /* Vertically align the checkbox with the text */
  cursor: pointer; /* Make the label clickable */
}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px; /* Space between checkbox and label text */
  width: 20px; /* Adjust checkbox size */
  height: 20px;
  cursor: pointer;
}

.checkbox-label {
  font-size: 18x; /* Adjust font size to match the example */
  color: var(--recycle-green); /* Adjust text color */
  font-weight: 600;
}




.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #4b5563;
}

.form-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 14px;
}

.form-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.button-group {
  display: flex; /* Aligns buttons in a row */
  gap: 12px; /* Adds space between the buttons */
  flex-wrap: wrap; /* Allows wrapping to the next line if there's not enough space */
}

.option-button {
  padding: 8px 16px; /* Button padding */
  border: 1px solid #e2e8f0; /* Border styling */
  border-radius: 4px; /* Rounded corners */
  background: white; /* Background color */
  color: #64748b; /* Text color */
  cursor: pointer; /* Pointer on hover */
  transition: all 0.2s; /* Smooth hover effect */
}

.option-button:hover {
  background: #f1f5f9; /* Light hover background */
}

.option-button.active {
  background: #2563eb; /* Active button background */
  color: white; /* Active button text color */
  border-color: #2563eb; /* Active button border color */
}


.validation-message {
  background: #fee2e2;
  color: #991b1b;
  padding: 12px;
  border-radius: 4px;
  margin-top: 16px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .summary-grid {
    grid-template-columns: 1fr;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .completed-by .button-group {
    flex-direction: column;
  }

  .option-button {
    width: 100%;
  }

  .h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: var(--lrs-blue);
}

  .item-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .item-name {
    min-width: unset;
    font-size: 0.9rem;
  }

  .item-quantity {
    font-size: 0.8rem;
  }

  .summary-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .item-cost {
    align-self: flex-end;
  }
}
</style>